._thanks {
  position: fixed;
  z-index: 999999;
  background-color: rgba(38, 38, 38, 0.85);
  width: 100vw;
  height: 100vh;
}

._lota_thanks {
  position: absolute;
  height: 75vh;
  max-width: 80vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
